import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import ScheduledMessagesAPI from '../../api/scheduledMessages';

export const state = {
  records: [],
  uiFlags: {
    isCreating: false,
    isDeleting: false
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getScheduled(_state) {
    return _state.records.length === 0 ? null : _state.records[0];
  }
};

export const actions = {
  create: async ({ commit }, data) => {
    commit(types.SET_SCHEDULED_MESSAGES_TEMPLATE_UI_FLAG, { isCreating: true });

    try {
      const response = await ScheduledMessagesAPI.create(data);

      commit(types.ADD_SCHEDULED_MESSAGES, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULED_MESSAGES_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_SCHEDULED_MESSAGES_TEMPLATE_UI_FLAG, { isDeleting: true });

    try {
      await ScheduledMessagesAPI.delete(id);

      commit(types.DELETE_SCHEDULED_MESSAGES, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_SCHEDULED_MESSAGES_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_SCHEDULED_MESSAGES_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_SCHEDULED_MESSAGES]: MutationHelpers.create,
  [types.SET_SCHEDULED_MESSAGE]: MutationHelpers.set,
  [types.DELETE_SCHEDULED_MESSAGES]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
