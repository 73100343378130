<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('TEMPLATE_PARAMETERIZATION.ADD.TITLE')"
      :header-content="$t('TEMPLATE_PARAMETERIZATION.ADD.DESCRIPTION')"
    />

    <timeline :current_page="steps.current" :total="steps.total" />

    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">
        <div v-if="steps.current === 1">
          <label :class="{ error: $v.selectedTemplate.$error }">
            {{ $t('TEMPLATE_PARAMETERIZATION.ADD.FORM.TEMPLATE.LABEL') }}

            <templates-picker
              v-if="!selectedTemplate"
              :inbox-id="inboxId"
              :remove-existing-parameterizations="true"
              @onSelect="pickTemplate"
            />

            <span v-if="$v.selectedTemplate.$error" class="message">
              {{ $t('TEMPLATE_PARAMETERIZATION.ADD.FORM.TEMPLATE.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="steps.current === 2">
          <template-parser
            v-if="selectedTemplate"
            :template="selectedTemplate"
            :parameterizations="generateParameterizations"
            :is-edit="isEdit"
            @sendMessage="getEventTemplate"
          />
        </div>

        <div v-if="steps.current === 3">
          <textarea
            rows="4"
            v-model="message"
            readonly
            class="template-input"
          />
        </div>
      </div>

      <div class="modal-footer">
        <woot-button
          @click.prevent="changePage('minus')"
          :isDisabled="canDisableButton"
        >
          {{ $t('GENERAL_SETTINGS.BACK') }}
        </woot-button>

        <woot-button v-if="canChangePage" @click.prevent="changePage('add')">
          {{ $t('TEMPLATE_PARAMETERIZATION.NEXT.BUTTON_TEXT') }}
        </woot-button>

        <woot-submit-button
          v-else
          type="submit"
          :button-text="$t('TEMPLATE_PARAMETERIZATION.ADD.SAVE_BUTTON_TEXT')"
          :loading="uiFlags.isCreating"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParserWithParameterization';
import Timeline from 'shared/components/Timeline';
import alertMixin from 'shared/mixins/alertMixin';
import parameterizationMixin from 'shared/mixins/parameterizationMixin';

import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    TemplatesPicker,
    TemplateParser,
    Timeline,
  },
  mixins: [alertMixin, parameterizationMixin],
  props: {
    inboxId: {
      required: true,
      type: Number,
    },
    selectedParameterization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      message: null,
      selectedTemplate: null,
      templateParams: null,
      parameterizations: [],
      steps: {
        init: 1,
        end: 3,
        total: 3,
        current: 1,
      },
    };
  },
  validations() {
    return {
      selectedTemplate: { required },
      message: { required },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'templateParameterizations/getUIFlags',
    }),
    canChangePage() {
      return this.steps.current !== this.steps.end;
    },
    canDisableButton() {
      return (
        this.steps.current === this.steps.init ||
        (this.steps.current === this.steps.end &&
          (this.uiFlags.isCreating || this.uiFlags.isUpdating)) ||
        (this.isEdit && this.steps.current <= 2)
      );
    },
    generateParameterizations() {
      if (!this.isEdit || this.steps.current === this.steps.init) {
        return {};
      }

      return {
        paramsHeader: this.generateParameterFormatting(
          this.selectedParameterization,
          this.wootConstants.HEADER
        ),
        paramsBody: this.generateParameterFormatting(
          this.selectedParameterization,
          this.wootConstants.BODY
        ),
      };
    },
    isEdit() {
      return Boolean(this.selectedParameterization?.id);
    },
  },
  methods: {
    ...mapActions('templateParameterizations', ['create', 'update']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const data = this.getParameterizationDetails();

        let emit = 'on-created';
        let message = 'CREATED_SUCCESS_MESSAGE';

        if (this.isEdit) {
          const { id } = this.selectedParameterization;
          await this.update({ id, data });

          emit = 'on-updated';
          message = 'UPDATED_SUCCESS_MESSAGE';
        } else {
          await this.create(data);
        }

        this.$emit(emit);
        this.showAlert(this.$t(`TEMPLATE_PARAMETERIZATION.ADD.API.${message}`));
      } catch (error) {
        console.log(error.message);
        const errorMessage = this.$t(
          'TEMPLATE_PARAMETERIZATION.ADD.API.ERROR_MESSAGE'
        );
        this.showAlert(errorMessage);
      }
    },
    pickTemplate(template) {
      this.selectedTemplate = template;
      this.changePage('add');
    },
    getEventTemplate(event) {
      const { message, processed_params } = event;

      this.message = message;
      this.templateParams = processed_params;

      this.changePage('add');
    },
    getParameterizationDetails() {
      const parameterizations = [];

      const header = Object.values(this.templateParams.header);
      const body = Object.values(this.templateParams.body);

      if (header.length) {
        parameterizations.push({
          key: '{{1}}',
          type: this.wootConstants.HEADER,
          value: header.pop(),
        });
      }

      if (body.length) {
        body.forEach((item, index) => {
          parameterizations.push({
            key: `{{${index + 1}}}`,
            type: this.wootConstants.BODY,
            value: item,
          });
        });
      }

      return {
        template_parameterizations: {
          inbox_id: this.inboxId,
          template_id: this.selectedTemplate.id,
          parameterizations,
        },
      };
    },
    changePage(type) {
      if (type === 'minus' && this.steps.current > this.steps.init) {
        if (this.steps.current === this.steps.end - 1) {
          this.selectedTemplate = null;

          this.$v.$reset();
        }

        --this.steps.current;

        this.message = null;
        this.templateParams = null;
      }

      if (type === 'add' && this.steps.current < this.steps.end) {
        this.$v.$touch();

        if (
          (this.steps.current === 1 && this.$v.selectedTemplate.$error) ||
          (this.steps.current === 2 && this.$v.message.$error)
        ) {
          return;
        }

        this.$v.$reset();

        ++this.steps.current;
      }
    },
    initializePopup() {
      if (!this.isEdit) {
        return;
      }

      const templates = this.$store.getters['inboxes/getWhatsAppTemplates'](
        this.inboxId
      );

      const { template, parameterizations } = this.selectedParameterization;

      this.parameterizations = parameterizations;
      this.selectedTemplate = templates.find(({ id }) => template.code === id);
      this.steps.current = 2;
    },
  },
  mounted() {
    this.initializePopup();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: auto !important;

    p {
      font-size: var(--font-size-default);
    }
  }

  .editor-wrap {
    width: 100% !important;
    margin-left: var(--space-small);
    margin-bottom: 0;
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}

.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}

.toggle-button {
  margin-bottom: var(--space-normal);
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>