<template>
  <section class="table-wrap">
    <ve-table
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      scroll-width="auto"
      max-height="calc(100vh - 14rem)"
    />

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('TEMPLATE_PARAMETERIZATION.LIST.404')"
    />

    <empty-state
      v-else-if="!isLoading && !items.length"
      :title="$t('TEMPLATE_PARAMETERIZATION.LIST.NO_CONTENT')"
    />

    <div v-if="isLoading" class="template--loader">
      <spinner />
      <span>{{ $t('TEMPLATE_PARAMETERIZATION.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner';
import EmptyState from 'dashboard/components/widgets/EmptyState';
import WootButton from 'dashboard/components/ui/WootButton';
import Content from './Content';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }

      return this.items;
    },
    columns() {
      return [
        {
          field: 'title',
          key: 'title',
          title: this.$t('TEMPLATE_PARAMETERIZATION.LIST.HEADER.NAME'),
          fixed: 'left',
          align: 'left',
          width: 290,
          renderBodyCell: ({ row }) => row.template.title,
        },
        {
          field: 'parameterizations',
          key: 'parameterizations',
          title: this.$t('TEMPLATE_PARAMETERIZATION.LIST.HEADER.CONTENT'),
          align: 'left',
          width: 'auto',
          renderBodyCell: ({ row }) => {
            return <Content item={row} />;
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('TEMPLATE_PARAMETERIZATION.LIST.HEADER.ACTIONS'),
          align: 'center',
          width: 200,
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <WootButton
                  size="tiny"
                  variant="smooth"
                  icon="edit"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-edit-click', row)}
                />

                <WootButton
                  size="tiny"
                  variant="smooth"
                  icon="dismiss-circle"
                  color-scheme="alert"
                  onClick={() => this.$emit('on-delete-click', row)}
                />
              </div>
            );
          },
        },
      ];
    },
    emptyMessage() {
      return this.items.length
        ? this.$t('TEMPLATE_PARAMETERIZATION.LIST.404')
        : this.$t('TEMPLATE_PARAMETERIZATION.LIST.NOT_FOUND');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);

    .ve-table-container {
      overflow-y: hidden !important;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    font-size: var(--font-size-mini) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;

    button {
      margin-left: var(--space-smaller);
    }
  }

  .ve-table-sort {
    top: -4px;
  }
}

.template--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}
</style>
