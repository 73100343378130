/* global axios */

import ApiClient from './ApiClient';

class AccountActions extends ApiClient {
  constructor() {
    super('actions', { accountScoped: true });
  }

  merge(parentId, childId) {
    return axios.post(`${this.url}/contact_merge`, {
      base_contact_id: parentId,
      mergee_contact_id: childId,
    });
  }

  mergeConversations(contactId, inboxId, conversationId) {
    return axios.post(`${this.url}/conversation_merge`, {
      contact_id: contactId,
      inbox_id: inboxId,
      conversation_id: conversationId,
    });
  }
}

export default new AccountActions();
