<template>
    <div class="wizard-body columns content-box small-9">
      <form class="row" @submit.prevent="onSubmitSlaTeam">
        <div class="medium-12 columns">
            <div class="row">
                <div class="medium-3 fs-small mt-08rem">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.TITLE') }}</div>
                <div>
                    <select v-model="sla_active" style="min-width: 120px;">
                        <option :value="true">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.ENABLE') }}</option>
                        <option :value="false">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.DISABLE') }}</option>
                    </select>
                </div>
            </div>
            <div v-if="sla_active" class="row">
                <div class="medium-12 columns">
                    <div class="alert alert-warning">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.ALERT') }}</div>
                    <div class="row mb-08rem">
                        <div class="fs-small medium-2">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.LIST.LABEL_PRIORITY') }}</div>
                        <div class="fs-small">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.LIST.LABEL_TIME') }}</div>
                    </div>
                    <div class="row" v-for="priority in priorities" :key="priority.value">
                        <div class="fs-small medium-2 mt-08rem">
                            <span class="label-priority" :style="{backgroundColor: priority.color}">{{ $t(`TEAMS_SETTINGS.CREATE_FLOW.SLA.LIST.PRIORITY.${priority.value}`) }}</span>
                        </div>
                        <div>
                            <select v-model="sla_config[priority.value]">
                                <option :value="null">{{ $t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.OPTION_DEFAULT') }}</option>
                                <option v-for="time in times" :key="time.value" :value="time.value">{{ $t(`TEAMS_SETTINGS.CREATE_FLOW.SLA.LIST.TIMES.${time.label}`) }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="medium-12 columns">
                <div class="sla_disable_img">
                    <img src="/assets/images/dashboard/priority/team_sla.svg" alt="SLA de Atendimento" />
                </div>
                <div class="sla_description">
                    <p class="cursor-pointer" v-html="$t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.DESCRIPTION')" @click="sla_active=true"></p>
                </div>
            </div>
            <div class="add-sla__footer">
                <woot-submit-button
                    :button-text="$t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.BTN_SUBMIT')"
                    :loading="submitInProgress"
                />
            </div>
        </div>
      </form>
    </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../index';
import { mapActions } from 'vuex';

export default {
    mixins: [alertMixin],
    data() {
        return {
            submitInProgress: false,
            sla_active: false,
            sla_config: {
                low: null,
                medium: null,
                high: null,
                urgent: null,
            },
            priorities: [
                { value: 'urgent', color: '#ea4335' },
                { value: 'high', color: '#ff9900' },
                { value: 'medium', color: '#f8e00f' },
                { value: 'low', color: '#2eae34' },
            ],
            times: [
                { value: 300, label: "TIME5MINUTES" },
                { value: 600, label: "TIME10MINUTES" },
                { value: 900, label: "TIME15MINUTES" },
                { value: 1200, label: "TIME20MINUTES" },
                { value: 1800, label: "TIME30MINUTES" },
            ],
        }
    },
    mounted() {
        this.sla_active = this.currentTeam.sla_active || this.sla_active;
        this.sla_config = this.currentTeam.sla_config || this.sla_config;
    },
    computed: {
        teamId() {
            return this.$route.params.teamId;
        },
        page() {
            return this.$route.params.page;
        },
        currentTeam() {
            return this.$store.getters['teams/getTeam'](this.teamId);
        },
        routeFinish() {
            return this.page === 'new' ? 'settings_teams_finish' : 'settings_teams_edit_finish';
        }
    },
    methods: {
        async onSubmitSlaTeam() {
            if (this.submitInProgress) return;
            this.submitInProgress = true;

            try {
                await this.$store.dispatch('teams/update', {
                    id: this.teamId,
                    sla_active: this.sla_active,
                    sla_config: this.sla_config,
                });

                router.replace({
                    name: this.routeFinish,
                    params: {
                        page: this.page,
                        teamId: this.teamId,
                    }
                });
            } catch (error) {
                this.showAlert(this.$t('TEAMS_SETTINGS.CREATE_FLOW.SLA.FORM.ERROR_MESSAGE'));
            } finally {
                this.submitInProgress = false;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.mt-08rem {
    margin-top: 0.8rem;
}
.mb-08rem {
    margin-bottom: 0.8rem;
}
.label-priority {
    font-size: 1.6rem;
    color: #fff;
    width: 150px;
    display: block;
    text-align: center;
    border-radius: 6px;
}
.alert {
    color: #030303;
    padding: 1rem 1.8rem;
    font-size: 1.5rem;
    border-radius: 5px;
    margin-bottom: 1.8rem;
    &.alert-warning {
        background: #FFF3D5;    
    }
}
.sla_disable_img {
    display: flex;
    justify-content: center;
    > img {
        width: 60vh;
    }
}
.sla_description {
    text-align: center;
    margin-top: 25px;
    color: #22469B;
}
.add-sla__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
}
</style>