<template>
  <div class="medium-12 columns">
    <div v-if="getTemplateHeader">
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.HEADER') }}</label>

      <textarea
        v-if="formatTypeTemplateHeader === 'TEXT' && processedStringHeader"
        v-model="processedStringHeader"
        rows="4"
        readonly
        class="template-input"
      />

      <file-upload
        v-else-if="formatTypeTemplateHeader !== 'TEXT' && !hasAttachments"
        ref="images"
        name="_file"
        :accept="acceptedFormats"
        :size="acceptedSize"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          class-names="button--upload"
          icon="file-upload"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        >
          <span v-if="formatTypeTemplateHeader === 'IMAGE'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_IMAGE') }}
          </span>

          <span v-if="formatTypeTemplateHeader === 'DOCUMENT'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_DOCUMENT') }}
          </span>

          <span v-if="formatTypeTemplateHeader === 'VIDEO'">
            {{ $t('WHATSAPP_TEMPLATES.PARSER.UPLOAD_VIDEO') }}
          </span>
        </woot-button>

        <small>
          Formatos permitidos: <span>{{ acceptedFormats }}</span>
        </small>
      </file-upload>
    </div>

    <div v-if="hasAttachments" class="attachment-preview-box">
      <attachment-preview
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>

    <div>
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.BODY') }}</label>
      <textarea
        v-model="processedStringBody"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div v-if="processedStringFooter">
      <label>{{ $t('WHATSAPP_TEMPLATES.PARSER.FOOTER') }}</label>
      <textarea
        v-model="processedStringFooter"
        rows="4"
        readonly
        class="template-input"
      />
    </div>

    <div v-if="variablesHeader" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_HEADER') }}
      </p>
      <div
        v-for="(variable, key) in processedParamsHeader"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsHeader[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
          :readonly="
            canDisable(key, processedParamsHeader[key], wootConstants.HEADER)
          "
        />
      </div>
    </div>

    <div v-if="variablesBody" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_BODY') }}
      </p>
      <div
        v-for="(variable, key) in processedParamsBody"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParamsBody[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
          :readonly="
            canDisable(key, processedParamsBody[key], wootConstants.BODY)
          "
          @blur="onBlur(processedParamsBody)"
        />
      </div>
      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>

    <footer :class="{ 'custom-footer': type === 'campaign' }">
      <woot-button
        v-if="type === 'default'"
        variant="smooth"
        @click="$emit('resetTemplate')"
      >
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>

      <woot-button @click="sendMessage" v-if="type !== 'cadence'">
        {{
          $t(
            type === 'campaign'
              ? 'CAMPAIGN.NEXT.BUTTON_TEXT'
              : 'WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL'
          )
        }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import FileUpload from 'vue-upload-component';
import alertMixin from 'shared/mixins/alertMixin';
import parameterizationMixin from 'shared/mixins/parameterizationMixin';
import templateWppMixin from 'shared/mixins/templateWppMixin';

export default {
  components: { AttachmentPreview, FileUpload },
  mixins: [alertMixin, parameterizationMixin, templateWppMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'default',
    },
    enableParameterization: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sendMessage() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      let message = null;
      let processed_params = null;

      if (this.enableParameterization && this.parameterization) {
        message = this.getParameterizedMessages();
        processed_params = this.getProcessedParamsParameterized();
      } else {
        message = this.getMessage();
        processed_params = this.getProcessedParams();
      }

      const payload = {
        message,
        templateParams: {
          name: this.template.name,
          category: this.template.category,
          language: this.template.language,
          namespace: this.template.namespace,
          processed_params,
        },
      };

      if (this.hasAnAttach()) {
        const processed_params = this.getFileProcessedParameters();
        payload.templateParams.file_processed_parameters = processed_params;
      }

      this.$emit('sendMessage', payload);
      this.sendAttachmentPrivateMessage();
    },
    sendAttachmentPrivateMessage() {
      if (!this.hasAnAttach()) return;

      const file = this.attachedFiles[0];
      file.isPrivate = true;

      const payload = {
        message: '',
        private: true,
        files: [file.blobSignedId],
        attachedFile: this.resource,
      };

      this.$emit('sendMessage', payload);
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  padding: var(--space-one);
}

.variables-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-one);
}

.template__variable-item {
  align-items: center;
  display: flex;
  margin-bottom: var(--space-one);

  .label {
    font-size: var(--font-size-mini);
  }

  .variable-input {
    flex: 1;
    font-size: var(--font-size-small);
    margin-left: var(--space-one);
  }

  .variable-label {
    background-color: var(--s-75);
    border-radius: var(--border-radius-normal);
    display: inline-block;
    font-size: var(--font-size-mini);
    padding: var(--space-one) var(--space-medium);
  }
}

footer {
  display: flex;
  justify-content: flex-end;

  &.custom-footer {
    position: absolute;
    right: var(--space-large);
    margin-top: var(--space-small);
  }

  button {
    margin-left: var(--space-one);
  }
}
.error {
  background-color: var(--r-100);
  border-radius: var(--border-radius-normal);
  color: var(--r-800);
  padding: var(--space-one);
  text-align: center;
}
.template-input {
  background-color: var(--s-25);
}

.preview-item__wrap,
.file-uploads-html5 {
  margin-top: var(--space-micro);
  margin-bottom: var(--space-one);

  small {
    margin-left: var(--space-normal);
    font-size: var(--font-size-micro);

    span {
      text-transform: uppercase;
    }
  }
}
</style>
