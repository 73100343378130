<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('SCHEDULED_MESSAGE.EDIT.TITLE')"
      :header-content="$t('SCHEDULED_MESSAGE.EDIT.DESCRIPTION')"
    />

    <form @submit.prevent="$emit('on-delete-click')">
      <div class="card-box">
        <div class="card-box-content">
          <h2>{{ $t('SCHEDULED_MESSAGE.EDIT.FORM.SCHEDULED_AT') }}</h2>
          <p class="strong">{{ getScheduledAt }}</p>
        </div>

        <div class="card-box-content">
          <h2>{{ $t('SCHEDULED_MESSAGE.EDIT.FORM.TEMPLATE') }}</h2>
          <p class="strong" v-html="scheduled.content"></p>
        </div>

        <div class="card-box-content">
          <h2>{{ $t('SCHEDULED_MESSAGE.EDIT.FORM.CANCELLATION') }}</h2>
          <div>
            <fluent-icon
              :icon="getIcon.name"
              :class="getIcon.classname"
              v-tooltip="getIcon.tooltip"
              size="26"
            />
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <woot-button size="small" variant="smooth" color-scheme="alert">
          {{ $t('SCHEDULED_MESSAGE.EDIT.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  props: {
    scheduled: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getScheduledAt() {
      return format(
        new Date(this.scheduled.scheduled_at),
        'dd/MM/yyyy - HH:mm'
      );
    },
    getIcon() {
      let name = 'dismiss-circle';
      let classname = 'icon-dismiss';
      let tooltip = this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED');

      if (this.scheduled.enable_cancellation) {
        name = 'checkmark-circle';
        classname = 'icon-check';
        tooltip = this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED');
      }

      return { name, classname, tooltip };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  padding: var(--space-one);
  background-color: var(--b-50);
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--space-two);

  .card-box-content {
    margin-bottom: var(--space-two);

    h2 {
      font-size: var(--font-size-small);
      color: var(--b-800);
    }

    p {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-normal);
      color: var(--b-700);
    }

    .icon-dismiss {
      color: var(--r-800);
    }

    .icon-check {
      color: var(--g-800);
    }
  }
}

.modal-footer {
  width: 100%;
  justify-content: center !important;
}
</style>