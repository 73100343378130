import { mapActions, mapGetters } from 'vuex';
import { TEMPLATE_TYPE } from 'widget/helpers/template';
import {
  getMessageVariables,
  replaceVariablesInMessage,
} from '@chatwoot/utils';

export default {
  data() {
    return {
      wootConstants: TEMPLATE_TYPE,
    };
  },
  methods: {
    ...mapActions('templateParameterizations', ['search']),
    async initializeParameterization() {
      if (!this.enableParameterization) {
        return;
      }

      if (
        !this.parameterization ||
        this.parameterization?.template?.code !== this.template.id
      ) {
        await this.search(this.template.id);
      }

      this.applyParameterizations();
    },
    applyParameterizations() {
      if (!this.parameterization) {
        return;
      }

      const header = this.generateParameterFormatting(
        this.parameterization,
        TEMPLATE_TYPE.HEADER
      );

      const body = this.generateParameterFormatting(
        this.parameterization,
        TEMPLATE_TYPE.BODY
      );

      setTimeout(() => {
        this.processedParamsHeader = header;
        this.processedParamsBody = body;
      }, 200);
    },
    generateParameterFormatting(content, type) {
      const { parameterizations } = content;
      let params = {};

      parameterizations
        .filter(item => item.type === type)
        .forEach(item => {
          const key = item.key.replace(/[{}]/g, '');
          params[key] = item.value;
        });

      return params;
    },
    getParameterizedMessages() {
      let message = '';

      if (this.processedStringHeader) {
        const updatedMessage = this.replaceMessages(this.processedStringHeader);

        message = `*${updatedMessage}* \n\n`;
      }

      const updatedMessage = this.replaceMessages(this.processedStringBody);

      message = `${message} ${updatedMessage}`;

      if (this.processedStringFooter) {
        message = `${message} \n\n ${this.processedStringFooter}`;
      }

      return message;
    },
    getProcessedParamsParameterized() {
      const header = {};
      const body = {};

      this.parameterization?.parameterizations?.forEach(param => {
        const keyWithoutBraces = param.key.replace(/[{}]/g, '');

        if (
          param.type === TEMPLATE_TYPE.HEADER &&
          this.processedParamsHeader[keyWithoutBraces]
        ) {
          header[keyWithoutBraces] = this.replaceMessages(
            this.processedParamsHeader[keyWithoutBraces]
          );
        }

        if (
          param.type === TEMPLATE_TYPE.BODY &&
          this.processedParamsBody[keyWithoutBraces]
        ) {
          body[keyWithoutBraces] = this.replaceMessages(
            this.processedParamsBody[keyWithoutBraces]
          );
        }
      });

      return { header, body };
    },
    replaceMessages(message) {
      return replaceVariablesInMessage({
        message,
        variables: this.messageVariables,
      });
    },
    canDisable(key, value, type) {
      if (!this.enableParameterization || !this.parameterization) {
        return false;
      }

      return this.parameterization?.parameterizations?.some(item => {
        return (
          item.type === type &&
          item.key === `{{${key}}}` &&
          item.value &&
          item.value === value
        );
      });
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      parameterization: 'templateParameterizations/getParameterization',
    }),
    messageVariables() {
      return getMessageVariables({ conversation: this.currentChat });
    },
  },
  mounted() {
    this.initializeParameterization();
  },
};