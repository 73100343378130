/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Voll360 extends CacheEnabledApiClient {
  constructor() {
    super('voll_tabulations', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'voll_tabulations';
  }

  async getTabulations(domain, api_key) {
    try {
      let response = await axios.get(`${this.url}?domain=${domain}&api_key=${api_key}`);

      return response.data.data ?? [];
    } catch (error) {
      return null;
    }
  }
}

export default new Voll360();
