<template>
  <form @submit.prevent="onSubmit">
    <div class="merge-conversations">
      <p>{{ $t('MERGE_CONVERSATIONS.FORM.LABEL_INBOX_NAME') }}: <strong>{{ inbox.name }}</strong></p>
      <p>{{ $t('MERGE_CONVERSATIONS.FORM.LABEL_TOTAL_CONVERSATION') }}: <strong>{{ previousConversations.length }}</strong></p>
    </div>

    <div class="footer">
      <woot-button variant="clear" @click.prevent="onCancel">
        {{ $t('MERGE_CONVERSATIONS.FORM.CANCEL') }}
      </woot-button>
      <woot-button type="submit" :is-loading="isMerging">
        {{ $t('MERGE_CONVERSATIONS.FORM.SUBMIT') }}
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isMerging: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
    },
    conversationId() {
      return this.currentChat.id;
    },
    conversations() {
      return this.$store.getters['contactConversations/getContactConversation'](
        this.contact.id
      );
    },
    previousConversations() {
      return this.conversations.filter(
        conversation => (conversation.id !== Number(this.conversationId) && conversation.inbox_id === this.currentChat.inbox_id)
      );
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        contactId: this.contact.id,
        inboxId: this.currentChat.inbox_id, 
        conversationId: this.conversationId,
      });
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: var(--space-medium);
  display: flex;
  justify-content: flex-end;
}

.error .message {
  margin-top: 0;
}

.label--merge-warning {
  margin-left: var(--space-small);
}
</style>
