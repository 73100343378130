<template>
  <div>
    <woot-modal :show.sync="showPopupDetails" :on-close="hidePopupDetails">
      <show
        @on-close="hidePopupDetails"
        @on-delete-click="openDeletePopup"
        :scheduled="getScheduled"
      />
    </woot-modal>

    <woot-modal :show.sync="showPopupCreate" :on-close="hidePopupCreate">
      <create @on-close="hidePopupCreate" />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.TITLE')"
      :message="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.YES')"
      :reject-text="$t('INBOX_MGMT.TEMPLATES.DELETE.CONFIRM.NO')"
      :is-loading="uiFlags.isDeleting"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Create from './Create';
import Show from './Show';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Create,
    Show,
  },
  mixins: [alertMixin],
  props: {
    showPopupCreate: {
      type: Boolean,
      default: false,
    },
    showPopupDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      getScheduled: 'scheduledMessages/getScheduled',
      uiFlags: 'scheduledMessages/getUIFlags',
    }),
  },
  methods: {
    ...mapActions('scheduledMessages', ['delete']),
    async deleteScheduled(id) {
      try {
        await this.delete(id);

        this.showAlert(this.$t('SCHEDULED_MESSAGE.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('SCHEDULED_MESSAGE.DELETE.API.ERROR_MESSAGE'));
      } finally {
        this.closeDeletePopup();
      }
    },
    hidePopupCreate() {
      this.$emit('hide-popup-create');
    },
    hidePopupDetails() {
      this.$emit('hide-popup-details');
    },
    openDeletePopup() {
      this.hidePopupDetails();

      setTimeout(() => (this.showDeleteConfirmationPopup = true), 100);
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      const { id } = this.getScheduled;

      this.deleteScheduled(id);
    },
  },
};
</script>