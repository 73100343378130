<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('SCHEDULED_MESSAGE.ADD.TITLE')"
      :header-content="$t('SCHEDULED_MESSAGE.ADD.DESCRIPTION')"
    />

    <timeline :current_page="page.current" :total="page.total" />

    <form class="row" @submit.prevent="submit">
      <div class="medium-12 columns">
        <div v-if="page.current === 1">
          <label :class="{ error: $v.scheduledAt.$error }">
            {{ $t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.LABEL') }}
            <woot-date-time-picker
              :value="scheduledAt"
              :can-disable-past-hours="true"
              :confirm-text="
                $t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.CONFIRM')
              "
              :placeholder="
                $t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')
              "
              @change="onChange"
            />

            <span v-if="$v.scheduledAt.$error" class="message">
              {{ $t('SCHEDULED_MESSAGE.ADD.FORM.SCHEDULED_AT.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page.current === 2">
          <label :class="{ error: $v.selectedTemplate.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.TEMPLATE.LABEL') }}

            <templates-picker
              v-if="!selectedTemplate"
              :inbox-id="inboxId"
              :template-filter="templateFilters"
              @onSelect="pickTemplate"
            />

            <span v-if="$v.selectedTemplate.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.TEMPLATE.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page.current === 3">
          <label :class="{ error: $v.attachedFiles.$error }">
            <span v-if="$v.attachedFiles.$error" class="message custom-error">
              {{ $t('CAMPAIGN.ADD.FORM.FILE.ERROR') }}
            </span>
          </label>

          <template-parser
            v-if="selectedTemplate"
            type="campaign"
            :template="selectedTemplate"
            @sendMessage="getEventTemplate"
          />
        </div>

        <div v-if="page.current === 4">
          <label>
            {{ $t('SCHEDULED_MESSAGE.ADD.FORM.CANCELLATION.LABEL') }}

            <woot-switch v-model="enableCancellation" />
          </label>
        </div>
      </div>

      <div class="modal-footer">
        <woot-button
          @click.prevent="changePage('minus')"
          :isDisabled="canDisableButton"
        >
          {{ $t('GENERAL_SETTINGS.BACK') }}
        </woot-button>

        <woot-button v-if="canChangePage" @click.prevent="changePage('add')">
          {{ $t('SCHEDULED_MESSAGE.NEXT.BUTTON_TEXT') }}
        </woot-button>

        <woot-button v-else :is-loading="uiFlags.isCreating">
          {{ $t('SCHEDULED_MESSAGE.ADD.CREATE_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import FileUpload from 'vue-upload-component';

import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser';
import Timeline from 'shared/components/Timeline';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    AttachmentPreview,
    FileUpload,
    TemplatesPicker,
    TemplateParser,
    Timeline,
    WootDateTimePicker,
  },
  mixins: [alertMixin],
  data() {
    return {
      message: null,
      selectedInbox: null,
      selectedTemplate: null,
      scheduledAt: null,
      templateParams: null,
      attachedFiles: null,
      enableCancellation: false,
      page: {
        init: 1,
        end: 4,
        total: 4,
        current: 1,
      },
    };
  },
  validations() {
    return {
      scheduledAt: { required },
      selectedTemplate: { required },
      attachedFiles: {
        required: requiredIf(function () {
          return (
            this.formatTypeTemplateHeader &&
            this.formatTypeTemplateHeader != 'TEXT'
          );
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'scheduledMessages/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    templateFilters() {
      return {
        marketing: false,
        utility: true,
      };
    },
    canChangePage() {
      return this.page.current !== this.page.end;
    },
    canDisableButton() {
      return (
        this.page.current === this.page.init ||
        (this.page.current === this.page.end && this.uiFlags.isCreating)
      );
    },
    formatTypeTemplateHeader() {
      if (this.selectedTemplate) {
        const header = this.selectedTemplate.components.find(({ type }) => {
          return type === 'HEADER';
        });

        return header?.format;
      }
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
  },
  methods: {
    ...mapActions('scheduledMessages', ['create']),
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const data = this.getScheduledDetails();

        await this.create({ scheduled_messages: data });

        this.onClose();
        this.showAlert(this.$t('SCHEDULED_MESSAGE.ADD.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage = this.$t('SCHEDULED_MESSAGE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    pickTemplate(template) {
      this.selectedTemplate = template;
      this.changePage('add');
    },
    getEventTemplate(event) {
      if (event.files) {
        this.attachedFiles = event.attachedFile;
      }

      if (!event.files) {
        const { message, templateParams } = event;

        this.message = message;
        this.templateParams = templateParams;
      }

      this.changePage('add');
    },
    changePage(type) {
      if (type === 'minus' && this.page.current > this.page.init) {
        if (this.page.current === this.page.end - 1) {
          this.selectedTemplate = null;
          this.attachedFiles = null;

          this.$v.$reset();
        }

        --this.page.current;
      }

      if (type === 'add' && this.page.current < this.page.end) {
        this.$v.$touch();

        if (
          (this.page.current === 1 && this.$v.scheduledAt.$error) ||
          (this.page.current === 2 && this.$v.selectedTemplate.$error) ||
          (this.page.current === 3 && this.$v.attachedFiles.$error)
        ) {
          return;
        }

        this.$v.$reset();

        ++this.page.current;
      }
    },
    getScheduledDetails() {
      let data = {
        enable_cancellation: this.enableCancellation,
        conversation_id: this.currentChat.primary_key,
        content: this.message,
        scheduled_at: this.scheduledAt,
        trigger_rules: {
          template_params: this.templateParams,
        },
      };

      if (this.attachedFiles) {
        data.file = this.attachedFiles;
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}

.toggle-button {
  margin-bottom: var(--space-normal);
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>