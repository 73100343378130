import ApiClient from './ApiClient';

export const buildParams = (page, sortAttr = 'id', search = '', limit = 15) => {
  let params = `page=${page}&limit=${limit}&sort=${sortAttr}`;

  if (search) {
    params = `${params}&q=${search}`;
  }

  return params;
};

class TemplateParameterizations extends ApiClient {
  constructor() {
    super('template_parameterizations', { accountScoped: true });
  }

  get(page, sortAttr, limit) {
    let requestURL = `${this.url}?${buildParams(
      page,
      sortAttr,
      null,
      limit
    )}`;

    return axios.get(requestURL);
  }

  //custom function
  search(id) {
    let requestURL = `${this.url}/search?q=${id}`;

    return axios.get(requestURL);
  }
}

export default new TemplateParameterizations();
