<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="$t('MERGE_CONVERSATIONS.TITLE')"
      :header-content="$t('MERGE_CONVERSATIONS.DESCRIPTION')"
    />

    <merge-conversation
      :contact="primaryContact"
      :is-merging="uiFlags.isMerging"
      @cancel="onClose"
      @submit="onMergeConversations"
    />
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import MergeConversation from 'dashboard/modules/conversations/components/MergeConversation';

import { mapGetters } from 'vuex';
import { CONVERSATION_EVENTS } from '../../helper/AnalyticsHelper/events';

export default {
  components: { MergeConversation },
  mixins: [alertMixin],
  props: {
    primaryContact: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
    async onMergeConversations(payload) {
      this.$track(CONVERSATION_EVENTS.MERGED_CONVERSATIONS);
      try {
        await this.$store.dispatch('contacts/merge_conversations', {
          ...payload,
        });
        this.showAlert(this.$t('MERGE_CONVERSATIONS.FORM.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        console.error(error);
        this.showAlert(this.$t('MERGE_CONVERSATIONS.FORM.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
